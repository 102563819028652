'use strict';

(function (w, d, $, undefined) {

  var SITE = {

    Init: function () {

      var self = this;
      SITE.Helper.Import(self);
    },

    Helper: {
      Import: function (self) {
        var obj;

        for (obj in self) {
          if (self.hasOwnProperty(obj)) {
            var _method = self[obj];

            if (_method.selector !== undefined && _method.init !== undefined) {
              if ($(_method.selector).length) {

                _method.init();
              }
            }
          }
        }
      },
      GetMethod: function (object, methodName) {
        return function () {
          object[methodName].apply(object, arguments);
        };
      },
      GetQueryString: function (href) {
        var str = href || location.search,
          result = {},
          queryString = str.substring(1),
          re = /([^&=]+)=([^&]*)/g, m;

        while (m = re.exec(queryString)) {
          result[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
        }

        return result;
      },
      GetCookie: function (name) {
        var dc = document.cookie,
          prefix = name + "=",
          begin = dc.indexOf("; " + prefix);
        if (begin == -1) {
          begin = dc.indexOf(prefix);
          if (begin != 0) return null;
        } else {
          begin += 2;
          var end = document.cookie.indexOf(";", begin);
          if (end == -1) {
            end = dc.length;
          }
        }
        return unescape(dc.substring(begin + prefix.length, end));
      },
      GetRandomNumber: function () {
        return new Date().getTime() * (Math.random() * 1000000000);
      },
      IsNotEmpty: function (Object) {
        return Object.length > 0;
      },
    },

    Initialize: {
      selector: document,

      init: function () {
        // Validation 
        $.validator.addMethod(
          "regex",
          function (value, element, regexp) {
            var re = new RegExp(regexp);
            return this.optional(element) || re.test(value);
          }
        );

        // Hash Load Window
        window.onload = function () {
          let timer = setInterval(function () {
            let LocationHash = location.hash;
            (LocationHash) ? SITE.navControl.animateScroll(LocationHash) : false;
            clearInterval(timer);
          }, 500);
        }
      }
    },

    // Image SVG Support
    svgSupport: {
      selector: 'img[src*=".svg"]',

      init: () => {
        let self = this;
        let imgs = document.querySelectorAll('img[src*=".svg"]');

        for (let i = 0; i < imgs.length; i++) {
          imgs[i].onerror = (e) => {
            e.currentTarget.src = e.currentTarget.dataset.src
          };
        }
      },

      changeSrc: (e) => {
      }

    },

    // Section Full Browser Height
    sectionHeight: {
      selector: 'section',

      navHeight: document.querySelector('.header > nav').clientHeight,

      init: function () {
        let wWidth = window.innerWidth;

        if (wWidth > 1270) {
          this.sections = document.querySelectorAll(this.selector);
          this.sizing();
          window.onresize = this.sizing.bind(this);
        }
      },

      sizing: function () {
        let height = (window.innerHeight - this.navHeight);
        let sections = this.sections;
        for (let i = 0; i < sections.length; i++) {
          sections[i].style.minHeight = height + 'px';
        }
      }

    },

    // Navigation Control
    navControl: {
      selector: '#navbar-collapse-1',

      headerHeight: (window.innerWidth > 768) ? document.querySelector('.header > nav').offsetHeight : 0,

      navbar: document.querySelector('.header > .navbar'),

      init: function () {
        let self = this;
        let wWidth = window.innerWidth;

        if (wWidth < 768) {
          $(self.selector).closest('.navbar').removeClass('navbar-fixed-top');
        }

        $(window).on('scroll', self.windowScroll.bind(this));

        $(self.navbar).find(self.selector + ' > ul li a[data-toggle="scroll"]').on('click', self.sectionTop.bind(self));

        $('.navbar-brand-link').click(function (e) {
            $('html, body').animate({
              scrollTop: 0
            }, 1000);
        });

      


      },

      windowScroll: function (e) {
        let self = this;
        let fromTop = (window.pageYOffset + self.headerHeight + window.innerHeight / 2);

        $('main section').map(function (i, e) {
          let id = e.id.replace('_section', '');

          if (id) {
            // Position Control
            if (e.offsetTop < fromTop) {
              let activeItem = $(self.selector).find('> ul li a[href="#' + id + '"]');
              // Active nav item
              self.navActiveItem(activeItem);
              $(e).addClass('fade');
            } else {
              $(e).removeClass('fade');
            }
          }

          if (window.pageYOffset < window.innerHeight / 2) {
            self.navActiveItem();

          }
        });


        // Navbar Scroll Down Change Style
        if (window.pageYOffset > window.innerHeight / 3) {
          self.navbar.classList.add('scrollDown');
        } else {
          self.navbar.classList.remove('scrollDown');
        }


      },

      navActiveItem: function (item) {
        let self = this;
        $(self.selector).find('> ul li a').not(item).parent().removeClass('active');
        (item) ? item.parent().addClass('active') : false;
      },

      sectionTop: function (e) {
        e.preventDefault();
        let self = this;
        let item = e.currentTarget;
        self.animateScroll(item.hash);
        return false;
      },

      SetHistoryHash: function (hash) {
        let Hash = hash.replace('_section', '');

        // if (history.pushState) {
        //   history.pushState(null, null, Hash);
        // }
        // else {
        location.hash = Hash;
        // }
      },

      animateScroll: function (h) {
        let self = this;
        let hash = h + '_section';
        let el = document.querySelector(hash);
        let offset = el.offsetTop;
        let target = (offset - self.headerHeight);

        let query = SITE.Helper.GetQueryString().s;



        if (window.pageYOffset !== target) {
          $('html, body').animate({
            scrollTop: target
          }, 1000, function (event) {
            self.SetHistoryHash(hash);
            return false;
          });
        }


      },
    },

    // Navbar Dropdown
    navbarDropdown: {
      selector: '#navbar-collapse-1',

      openSub: '',

      init: function () {
        let self = this;
        let wrapper = document.getElementById(this.selector.replace('#', '')).querySelector('.dropdown-wrapper');

        // Sub Dropdown
        $(wrapper).find(' > ul > li').hover(function () {
          let classList = this.classList.value;
          this.classList.add('active');
          
          $(self.openSub).hide();
          $(this).closest('ul').find('> li').not(this).removeClass('active');

          if (classList.indexOf('dropdown') > -1) {
            let SubId = this.getElementsByTagName('a')[0].dataset.sub;
            let Sub = $(wrapper).find(SubId)[0];
            Sub.open = true;
            
            self.openSub = Sub;
            $(Sub).show();
          }
        }, function () {
          
        });


        // // First Sub Item Active
        $(self.selector + ' .nav').find('> li.dropdown').hover(function () {

          $(self.openSub).hide(); // Last open sub hide
          $(wrapper).find('> ul > li').removeClass('active');
          
          let FirstItem = $(this).find('.dropdown-wrapper > ul > li').eq(0);
          FirstItem.addClass('active');
          let FirstSub = $(FirstItem.find('a')[0].dataset.sub);
          FirstSub.show();
          FirstSub[0].open = true;
        });

      }
    },

    // Referances Carousel
    referencesCarousel: {
      selector: '#our-references-carousel',

      options: {
        slideSelector: $(this).find('.our-references-item'),
        nextSelector: $('#our-references-next-btn'),
        prevSelector: $('#our-references-prev-btn'),
        nextText: '',
        prevText: '',
        auto: false
      },

      init: function () {
        let self = this;

        self.slider = $(self.selector).bxSlider(self.options);

        let controlWrapper = document.getElementById('our-references-carousel-controls');

        let next = controlWrapper.querySelector('.carousel-next-btn');
        next.addEventListener('click', self.slider.goToNextSlide);

        let prev = controlWrapper.querySelector('.carousel-prev-btn');
        prev.addEventListener('click', self.slider.goToPrevSlide);
      }
    },

    // Home Carousel
    homeCarousel: {
      selector: '#home-carousel_section',
      activeClass: 'active',
      options: {
        slideSelector: $(this).find('.carousel-item'),
        nextText: '',
        prevText: '',
        interval:8000,  
        auto: true,
        autoDelay: 8000,
        autoHover: true,
        speed:1000,
        pause:8000,
        pager: false,
        stopAutoOnClick:true,        
        onSliderLoad: function (currentIndex) {
          $('#home-carousel_section .carousel-item').eq(0).find('.sliderCaption').addClass(this.activeClass);
          $(this.getCurrentSlideElement()).find('.sliderCaption').addClass('active');
        },
        onSlideAfter: function (el, oldIndex, newIndex) {
          $(el).find('.sliderCaption').addClass('active');
          $('#home-carousel_section .carousel-item').not(el).find('.sliderCaption').removeClass('active');
        }
      },

      init: function () {
        let self = this;
        this.slider = $(this.selector).find('.carousel').bxSlider(this.options);
      }
    },

    // Sidebar Control
    sidebarControl: {
      selector: '.sidebar',

      init: function () {
        let self = this;
        $(self.selector + ' .dropdown a').click(self.toggle.bind(self));

        $(self.selector + ' .title').click(self.MobileDropdown.bind(self));
      },

      toggle: function (e) {
        let target = e.currentTarget, openClass = 'active';
        (target.parentElement.classList.value.indexOf(openClass) < 0) ? target.parentElement.classList.add(openClass) : target.parentElement.classList.remove(openClass);
      },

      MobileDropdown: function () {
        let self = this;
        $(self.selector).toggleClass('open');
      }
    },

    // Career Datatable
    careerDatatable: {
      selector: '#career-datatable',

      options: {
        "ajax": '/assets/data/career.json',
        "searching": true,
        "dom": '',
        "bLengthChange": false,
        "paging": false,
        "info": false,
        "columns": [
          { "data": "Id" },
          { "data": "Title" },
          { "data": "Location" },
          { "data": "CategoryName" }
        ],
        "initComplete": function () {
          this.api().columns().every(function (i) {
            let column = this;

            switch (i) {
              case 2:
                let location = document.getElementById('dTcareer_fLocation');

                location.addEventListener('change', function () {
                  let value = this.value;
                  column.search(value).draw();
                });

                column.data().unique().sort().each(function (d, j) {
                  let option = document.createElement('option');
                  option.value = d;
                  option.innerText = d;
                  location.appendChild(option);
                });
                break;

              case 3:
                let category = document.getElementById('dTcareer_fCategory');

                category.addEventListener('change', function () {
                  let value = this.value;
                  column.search(value).draw();
                })

                column.data().unique().sort().each(function (d, j) {
                  let option = document.createElement('option');
                  option.value = d;
                  option.innerText = d;
                  category.appendChild(option);
                });
                break;
              default:
                break;
            }

          });


        },
        columnDefs: [
          { targets: 0, sortable: false },
        ],
        order: [[1, "asc"]]
      },

      init: function () {
        let self = this;
        this.table = $(this.selector).DataTable(this.options);

        $(self.selector).find('tbody').on('click', 'td', self.AdvertisementsDetail.bind(self));
      },
      AdvertisementsDetail: function (e) {
        let self = this;

        let data = self.table.row($(e.currentTarget).closest('tr')).data();
        SITE.appealModal.Render(data);
      }
    },

    // Appeal Modal
    appealModal: {
      selector: '#appealModal',
      init: function (data) {
        let Self = this;
        // Doms
        Self.Doms = {
          Modal: document.querySelector(Self.selector),
          StepOne: document.getElementById('appeal-step-one'),
          StepTwo: document.getElementById('appeal-step-two'),
          AppealForm: document.getElementById('appeal-form'),
          FlipButton: document.getElementById('flipBtn')
        }

        // Data
        Self.Data = {
          GeneralQualifications: $(Self.selector).find('#GeneralQualifications')[0],
          JobDescription: $(Self.selector).find('#JobDescription')[0],
          AppealCode: $(Self.selector).find('#appeal-code')[0],
          AppealId: Self.Doms.AppealForm.AppealId
        }

        // Close Buttons Events
        $(Self.Doms.Modal).find('button.close').click(Self.ModalClose.bind(Self));

        // Flip Button
        Self.Doms.FlipButton.addEventListener('click', Self.Flip.bind(Self));

        // Modal Close Event & Reset
        $(Self.Doms.Modal).on('hidden.bs.modal', Self.ResetModal.bind(Self));


      },

      ModalShow: function () {
        $(this.Doms.Modal).modal('show');
      },

      ModalClose: function () {
        $(this.Doms.Modal).modal('hide');
      },

      Flip: function (e) {
        $(this.Doms.Modal).toggleClass('flip');

        let ReCaptchaContainer = document.getElementsByClassName('recaptcha')[0];
        let SiteKey = ReCaptchaContainer.dataset.key;

        console.log(ReCaptchaContainer);
        console.log(SiteKey);

        grecaptcha.render(ReCaptchaContainer, {
          sitekey: SiteKey
        });
      },

      ResetModal: function () {
        let Self = this;

        // Flip Reset
        Self.Doms.Modal.classList.remove('flip');

        // Reset Data

        

        Self.Data.AppealCode.innerHTML="";
        Self.Data.GeneralQualifications.innerHTML="";
        Self.Data.JobDescription.innerHTML="";
        Self.Data.AppealId = 0;
        Self.Doms.AppealForm.reset();
      },

      Render: function (data) {
        let Self = this;

        Self.Data.AppealCode.innerText = data.Id;
        Self.Data.AppealId = data.Id;
        Self.Data.GeneralQualifications.innerHTML=data.GeneralQualifications;
        Self.Data.JobDescription.innerHTML=data.JobDescription;

        Self.ModalShow();
      },



      SendAppealForm: function () {
        let self = this;
      }


    },

    // Maps Link ( Mobile Maps App Link )
    mapsLink: {
      selector: 'a.maps-link',

      urls: {
        ios: 'http://maps.apple.com/?ll=1111,2222&z=20',
        android: 'geo:1111,2222'

      },


      init: function () {
        let userAgent = navigator.userAgent,
          url = 'javascript:;';

        if (/android/i.test(userAgent)) {
          url = this.urls.android;
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          url = this.urls.ios;
        }

        this.setLink(url);

      },

      setLink: function (u) {
        $(this.selector).each(function (i, e) {
          e.href = u.replace('1111', e.dataset.lat).replace('2222', e.dataset.lgn);
        });
      }
    },

    PageDownBtn: {
      selector: '.pageDown',

      init: function () {
        $(this.selector).find('a').click(function () {
          console.log('asd');
          $($('.nav > li')[0]).find('a').click();
        });
      }
    },

    CustomFileInput: {
      selector: '.custom-file-input',



      OpenFile: function (e) {
        let Self = this;

        let FileInput = $(e.currentTarget).closest(Self.selector).find('input[type="file"]');
        FileInput.trigger('click');

      },

      init: function () {
        let Self = this;

        $(Self.selector).find('.btn').click(Self.OpenFile.bind(Self));
        Self.Label = $(Self.selector).find('label');


        let input = $(Self.selector).find('input[type="file"]')[0];
        Self.file = input;
        input.onchange = function () {
          $(this).closest(Self.selector).find('label').text(this.files[0].name);
        };
      }
    }

  }
  SITE.Init();
  w.SITE = SITE;

})(window, document, jQuery);